import React from "react";
import { ImageBackground, StyleSheet, Text } from "react-native";
import { useResponsiveScreenFontSize } from "react-native-responsive-dimensions";

export default function NewYear() {

    const fontsize = useResponsiveScreenFontSize(5);

    return(
        <ImageBackground source={require('../assets/firework.gif')} style={[StyleSheet.absoluteFill, styles.imagectn]}>
            <Text style={[styles.text, {fontSize: fontsize}]}>Happy New Year</Text>
        </ImageBackground>
    )
}

const styles = StyleSheet.create({
    text: {
        color: "white",
    },
    imagectn: {
        justifyContent: 'center',
        alignItems: 'center'
    }
  });
