import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, useColorScheme, View } from 'react-native';
import { useResponsiveScreenFontSize } from 'react-native-responsive-dimensions';
import NewYear from './src/NewYear';

interface Time{
  days : number;
  hours : number;
  min : number;
  sec : number;
}

export default function App() {

  const [newYear, setNewYear] = useState(false)
  const [date, setDate] = useState<Time>();
  const fontsize = useResponsiveScreenFontSize(8);
  const colorscheme = useColorScheme();

  function countdown() {  
    var year = new Date().getFullYear();
  
    var date = new Date(year+1, 0, 1);
    var i = 0;
        var time = (date.getTime() - Date.now());
        //alert(time);
        var days = time / 86400000;
        var hours = ((time % 86400000) / 3600000);
        var min = (((time % 86400000) % 3600000) / 60000);
        var sec = ((((time % 86400000) % 3600000) % 60000) / 1000);
        var days = Math.floor(days);
        hours = Math.floor(hours);
        min = Math.floor(min);
        sec = Math.floor(sec);
        console.log(days + "-" + hours + "-" + min + "-" + sec);  
        return {
          days: days,
          hours: hours,
          min: min,
          sec: sec
        } as Time;  
  
  }

  useEffect(() => {
    const sub = setInterval(() => {
      setDate(countdown());
    }, 500);
    return () => clearInterval(sub);
  }, []);

  useEffect(() => {
    if(date && isNewYear(date)){
      setNewYear(true)
    }
  }, [date])

  if(!date){
    return null;
  }

  const rendercountdown = () => {
    if(date.days > 0){
      return(
        `${date.days}:${date.hours.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${date.min.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${date.sec.toLocaleString(undefined, {minimumIntegerDigits: 2})}`
      )
    } else if(date.hours > 0){
      return (`${date.hours}:${date.min.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${date.sec.toLocaleString(undefined, {minimumIntegerDigits: 2})}`)
    } else {
      return (`${date.min}:${date.sec.toLocaleString(undefined, {minimumIntegerDigits: 2})}`)
    }
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: (colorscheme ?? "light") === "light"  ? '#fff' : "black",
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: "blue",
      fontSize: fontsize
    }
  });

  if(newYear){
    return(
      <NewYear />
    )
  }

  return (
    <View style={styles.container}>
      <Text adjustsFontSizeToFit style={styles.text}>{rendercountdown()}</Text>
      <StatusBar style="auto" />
    </View>
  );
}

const isNewYear = (date : Time) => {
  if(date.days == 0 && date.hours == 0 && date.min == 0 && date.sec == 0){
    return true
  }
  return false
}